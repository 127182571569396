export const genWaiterFormData = (groupId) => {
  const formData = new FormData()

  formData.append("group_id", groupId)
  formData.append("image", "")
  formData.append("name", "Taxa Garçom")
  formData.append("type", "bar")
  formData.append("description1", "")
  formData.append("description2", "")
  formData.append("price_sell", 100)
  formData.append("price_cost", 100)
  formData.append("has_variable", 1)
  formData.append("has_courtesy", 0)
  formData.append("status", 1)
  formData.append("favorite", 0)
  formData.append("warehouse", 0)
  formData.append("warehouse_type", "notControled")
  formData.append("print_qrcode", 0)
  formData.append("print_ticket", 0)
  formData.append("print_local", 0)
  formData.append("print_date", 0)
  formData.append("print_value", 0)
  formData.append("has_control", 0)
  formData.append("start_at", 0)
  formData.append("number_copy", 0)
  formData.append("painel_control", 0)
  formData.append("print_group", 0)
  formData.append("has_cut", 0)
  formData.append("print_plate", 0)
  formData.append("print_tolerance", 0)
  formData.append("has_tolerance", 0)
  formData.append("time_tolerance", 0)
  formData.append("take_tolerance", 0)
  formData.append("value_tolerance", 0)

  return formData
}
